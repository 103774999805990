<template>
  <div class="container mx-auto">
    <div
      class="w-full xl:w-3/4 lg:w-11/12 flex items-center h-screen mx-auto justify-center"
    >
      <div class="w-full lg:w-1/2 bg-white p-5 text-left rounded-lg">
        <form class="px-8 pt-6 pb-8 bg-white rounded">
          <img src="../assets/logo.png" width="200" class="mx-auto mb-6" />
          <div class="mb-4">
            <label
              class="block mb-2 text-sm font-bold text-gray-700"
              for="username"
              >E-mail</label
            >
            <input
              class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="username"
              v-model="form.email"
              type="text"
              placeholder="Nº Matricula"
            />
          </div>
          <div class="mb-4">
            <label
              class="block mb-2 text-sm font-bold text-gray-700"
              for="password"
              >Senha</label
            >
            <input
              class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              v-model="form.password"
              placeholder="Senha"
            />
            <p v-show="error" class="text-xs italic text-red-500">
              {{ errorMessage }}
            </p>
          </div>
          <div class="text-center">
            <button
              class="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
              type="button"
              @click="submit"
            >
              {{ loading ? 'Entrando...' : 'Entrar' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import router from '@/router/index'
export default {
  data() {
    return {
      error: false,
      form: {
        email: '',
        password: '',
      },
      loading: false,
      errorMessage: '',
    }
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    submit() {
      this.loading = true
      this.signIn(this.form)
        .then(() => {
          router.push('Dashboard')
          this.loading = false
        })
        .catch(err => {
          this.error = true
          this.loading = false
          this.$error('Email e/ou senha incorreto(s)');
          this.errorMessage = err.response.data.message
        })
    },
  },
}
</script>

<style lang="stylus" scoped></style>
